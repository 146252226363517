import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button, { ButtonVariant } from "../components/Button";
import Layout from "../components/Layout";
import { useLayout } from "../components/Layout/layout-context";
import List from "../components/List";
import Quote from "../components/Quote";
import Section from "../components/Section";

const Optimiser = () => {
  const [setShowModal] = useLayout();

  return (
    <Layout
      title="Optimiser ses parcours utilisateur"
      metaTitle="Optimiser le parcours utilisateur/client"
      description="Optimiser le parcours de vos utilisateurs est essentiel pour offrir une expérience unique qui répondra aux besoins de vos clients et visiteurs"
    >
      <p className="mt-main">
        Grâce à notre accompagnement, nos clients tirent parti de la
        connaissance qu'ils ont de leurs utilisateurs pour proposer un produit
        ou service parfaitement conforme à leurs attentes.
        <br />
        <br />
        L’exploitation d’objectifs stratégiques, de paramètres technologiques et
        de problématiques design permet d’anticiper les attentes des visiteurs
        et ainsi de rendre l’interface plus ergonomique, intuitive et facile
        d’utilisation.
      </p>

      <Section title="📈 Besoin d'une optimisation de votre parcours ?">
        <p className="mt-main">
          Nous souhaitons accompagner nos clients sur le long terme pour leur
          permettre quotidiennement de ressentir les avantages d'une vision
          centrée utilisateur sur leur business et l'évolution de leurs
          indicateurs clés.
        </p>
        <List
          data={[
            {
              title: `🎯 Augmentez votre taux de conversion`,
              text: `Après un état des lieux minutieux de votre site internet, nous
            ciblons les éléments qui transformeront davantage de visiteurs en
            acheteurs.`,
            },
            {
              title: `🎯 Mettez la créativité au service de vos idées`,
              text: `En travaillant en groupe autour d’ateliers impliquant les différents
            membres de votre équipe, celle-ci sera alors plus unie et soudée et
            chacun pourra exprimer son point de vue dans une vision d’ensemble
            bénéfique pour votre entreprise.`,
            },
            {
              title: `🎯 Conception de maquettes évolutives`,
              text: `L’inspection de votre site selon des critères ergonomiques reconnus
            et notre propre expérience experte nous permet de formuler des
            recommandations à appliquer afin d’optimiser durablement votre site.
            Nous produisons des maquettes (wireframes, prototypes, graphiques)
            qui seront soumises à des itérations permettant un progrès continu
            et régulier pour votre évolution.`,
            },
            {
              title: `🎯 Faites des choix éclairés grâce aux tests utilisateur`,
              text: `La mise en place de tests place l’utilisateur dans une situation
            précise où il sera amené à effectuer des tâches pré-établies. En
            analysant le comportement de ces utilisateurs face aux consignes
            données, nous obtenons des informations précieuses sur leurs choix
            et leurs préférences et pouvons ainsi formuler des recommandations
            qui aménageront le parcours d’un visiteur de votre site pour
            transformer celui-ci en potentiel client.`,
            },
          ]}
        />
        <Button
          variant={ButtonVariant.primary}
          onClick={() => setShowModal(true)}
          text="Améliorer mon parcours utilisateur"
          className="mx-auto mt-8 lg:mt-10"
        />
      </Section>

      <Section title="Ils nous ont fait confiance pour cette prestation">
        <div className="text-center">
          <StaticImage
            src="../images/logos/laguiole.jpg"
            alt="logo Laguiole"
            height={128}
          />
        </div>

        <Quote
          className="mt-main"
          picture={
            <StaticImage
              src="../images/photos/Jean-Nourrisson.jpg"
              alt="photo par défaut"
              className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
            />
          }
          nameAndTitle={
            <p>
              Jean Nourrisson
              <br />
              PDG d’Actiforge
            </p>
          }
          quote={
            <p className="italic">
              “Mon besoin initial était d’améliorer mon taux de conversion sur
              le module de personnalisation de couteau Laguiole de ma boutique
              en ligne Actiforge, notamment sur mobile.
              <br />
              <br />
              L’agence Donut Panic m’a proposé de prendre par la main les
              utilisateurs en les guidant plus facilement dans la conception de
              leur couteau Laguiole.
              <br />
              <br />
              Concrètement, cela s’est déroulé au travers de tests filmés
              réalisés par des utilisateurs pour comprendre le problème. Des
              maquettes ont ensuite été réalisées pour résoudre les problèmes
              qui bloquaient la conversion. Elles ont pu être données
              directement au développeur sans passer par un graphiste (donc
              rapide).
              <br />
              <br />
              Je n'ai pas mesuré le ROI donc pas de chiffres à apporter mais
              nous avons moins de questions de la part des internautes. La
              satisfaction client a donc été améliorée de façon significative.
              <br />
              <br />
              Le gros plus de Donut Panic a aussi été l'ajout d'un module
              Prestashop dédié au mobile. J'ignorais l'existence de ce module.
              Ce dernier a permis d'augmenter de 30% le temps de visite sur ce
              canal.”
            </p>
          }
        />
      </Section>

      <div className="mt-main lg:mt-18">
        <div className="text-center">
          <StaticImage
            src="../images/logos/pole-emploi.png"
            alt="logo Laguiole"
            height={128}
            layout="constrained"
          />
        </div>
        <Quote
          className="mt-main"
          picture={
            <StaticImage
              src="../images/photos/florentin.jpg"
              alt="photo de Florentin"
              className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
            />
          }
          nameAndTitle={
            <p>
              Florentin Rodio
              <br />
              Lead UX Designer & Ergonome Senior chez Pôle Emploi
            </p>
          }
          quote={
            <p className="italic">
              “Donut Panic est intervenu dans le cadre d’un de nos projets
              stratégiques impliquant une transformation très importante d’un de
              nos services clefs.
              <br />
              Très impliqué, à l’écoute et toujours prêt à s’adapter au contexte
              très politique de notre structure, Quentin a su nous épauler et
              faire valoir son expertise.
              <br />
              Doté d’une grande palette méthodologique, il a su s’adapter aux
              contraintes, contextes et spécificités des demandes du projet.
              <br />
              Très empathique et chaleureux, ce fut un véritable plaisir de
              travailler ensemble.”
            </p>
          }
        />
      </div>
    </Layout>
  );
};

export default Optimiser;
